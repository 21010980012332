import React from 'react'
import clsx from 'clsx'
import { Typography, useTheme } from '@mui/material'
import { GuardedImageTile } from '../tiles/GuardedImageTile'
import { INewsCard } from '@obeta/models/lib/models/News/News'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { TypographyLineClamp } from '../typography'
import { trackClick } from '@obeta/utils/lib/tracking'
import { isInternalLink } from '@obeta/utils/lib/isInternalLink'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import styles from './NewsCard.module.scss'
import { useImgProxyUrls } from '@obeta/data/lib/hooks/useImgProxyUrls'

type NewsCardOptions = {
  options?: {
    showCategory?: boolean
    textMaxLines?: number
  }
  classes?: {
    cardWrapperClassName?: string
    imageWrapperClassName?: string
    contentWrapperClassName?: string
    themeWrapper?: string
  }
}

type INewsCardProps = INewsCard & NewsCardOptions

export const NewsCard: React.FC<INewsCardProps> = (props) => {
  const {
    imageAlt,
    imageUrl,
    title,
    startDate,
    category,
    teaserText,
    id,
    options = {},
    classes,
    url,
  } = props

  const { cardWrapperClassName, imageWrapperClassName, contentWrapperClassName, themeWrapper } =
    classes || {}

  const { showCategory = true, textMaxLines } = options

  const history = useHistory()

  const getNewsDetails = () => {
    // track both: one action with a concrete name containing an id and one generic action containing specific infos in context
    trackClick(`teaser-card-${id}`)

    if (url) {
      trackClick('teaser-id-GENERIC', {
        type: 'static-page',
        url,
      })
      if (!isInternalLink(url)) {
        window.open(url, '_blank')
      } else {
        const formattedUrl = url.startsWith('/') ? url : `/${url}`
        history.push(formattedUrl)
      }
    } else {
      // DEPRECATED: we use StaticContent for now instead
      trackClick('teaser-id-GENERIC', {
        type: 'news-details',
        newsId: id,
      })
      history.push(`${ShopRoutes.NewsDetailPage}/${id}`)
    }
  }

  const DESKTOP_MIN_VIEWPORT_WIDTH = useTheme().breakpoints.values.lg
  const { imgProxyUrlDesktop } = useImgProxyUrls({
    url: imageUrl,
    desktopWidth: DESKTOP_MIN_VIEWPORT_WIDTH,
  })

  return (
    <div
      className={clsx(styles['news-card-wrapper'], cardWrapperClassName)}
      onClick={getNewsDetails}
      data-testid="news-card"
    >
      <div className={clsx(styles['image-wrapper'], imageWrapperClassName)}>
        <GuardedImageTile
          imageUrl={imgProxyUrlDesktop}
          alt={imageAlt}
          backgroundColor="transparent"
          imageFit="cover"
        />
      </div>
      <div className={clsx(styles['content-wrapper'], contentWrapperClassName)}>
        <div className={clsx(styles['theme-wrapper'], themeWrapper)}>
          {showCategory && <Typography variant="smallText">{category}</Typography>}
          <Typography variant="smallText">{startDate}</Typography>
        </div>
        <div className={styles['text-wrapper']}>
          <Typography variant="boldText" className={styles['title']}>
            {title}
          </Typography>
          <TypographyLineClamp lines={textMaxLines || 3} variant="body" className={styles.teaser}>
            {teaserText}
          </TypographyLineClamp>
        </div>
      </div>
    </div>
  )
}
